import React from 'react'
import { AboutSection, Blog, CarbonFreeSection, FAQ, Head, Hero, SegmentsSection, WallboxSection } from '@components'

export default () => <>
  <Head path="" />
  <Hero path="" />
  <AboutSection />
  <SegmentsSection />
  <CarbonFreeSection />
  <FAQ />
  <WallboxSection />
  <Blog />
</>